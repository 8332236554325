import { useRef, useState, useEffect } from 'react'
import { Dropdown, Flex, Icon, LinkAsButton, Text, Spacing } from 'influ-dms'
import { useOutsideClick } from '~/hooks/useOutsideClick'
import { bindLinksMap } from '~/hooks/useLink'
import { useTranslate } from '~/hooks/useTranslate'
import getConfig from 'next/config'
import { useRouter } from 'next/router'
import { get } from 'lodash'
import { ContainerWrapper } from './Container'
import { Logo } from './Logo'
import { MenuButtons } from './MenuButtons'
import { MenuButton } from './MenuButton'
import { DottedDivider } from './DottedDivider'
import { Account } from './Account'
import { AvatarContactHeader } from './AvatarContact/AvatarContactHeader'
import { AvatarContactAccounts } from './AvatarContact/AvatarContactAccounts'
import { AvatarContactItem } from './AvatarContact/AvatarContactItem'
import { AvatarContactHelpCenter } from './AvatarContact/AvatarContactHelpCenter'
import { AvatarContactSignOut } from './AvatarContact/AvatarContactSignOut'
import { RouterHelper } from '../../../routing/routerHelper'
import { PATHS } from '../../../constants'
import { useLanguageContext } from '../../../context/Language'
import { bottomPositionStyles, topPositionStyles } from './AvatarContact/styles'
import { dropdownStyles } from './Account/styles'
import { MenuDropdown } from './MenuDropdown'

const { publicRuntimeConfig } = getConfig()
const { helpCenterUrl } = publicRuntimeConfig
const showGetBeamer = (publicRuntimeConfig?.showBeamer && publicRuntimeConfig?.showBeamer === 'true')

const NavbarContent = (props) => {
  const {
    logoClick,
    brandName,
    brands,
    activeProduct,
    permissions,
    onProductClick,
    products,
    brandImg,
    handleUpgrade,
    isPublic,
    isTrial,
    showPricesAndPlans,
    contactFirstName,
    contactLastName,
    contactEmail,
    contactImg
  } = props
  const ref = useRef(null)
  const { t } = useTranslate()
  const router = useRouter()
  const brandId = get(router, 'query.brandId')
  const pathname = get(router, 'pathname')
  const [isExpanded, setIsExpanded] = useState(false)
  const [getStartedLink, setGetStartedLink] = useState('')
  const { locale } = useLanguageContext()
  const linksMap = new Map([
    ['get_started_link', setGetStartedLink]
  ])

  useEffect(() => {
    bindLinksMap(linksMap, locale)
  }, [locale])
  const [isAvatarOpen, setAvatarOpen] = useState(false)
  const fullName = contactFirstName + (contactLastName ? ' ' + contactLastName : '')
  const [menuDropdownOpened, setMenuDropdownOpened] = useState(false)
  const [productOpened, setProductOpened] = useState({})

  const helpCenterUrlByLang = helpCenterUrl && helpCenterUrl.replace('{0}', locale)

  const isAccountDefaultPage = router.pathname.indexOf('accountDefault') > -1
  const isProductsAndBillingPage = router.pathname.indexOf('productsAndBilling') > -1
  const isPricesAndPlansPage = router.pathname.indexOf('pricesAndPlans') > -1

  useOutsideClick(ref, () => setTimeout(() => { setIsExpanded(false); setMenuDropdownOpened(false) }, 200))

  const goTo = route => {
    setMenuDropdownOpened(false)
    router.push(RouterHelper.getRoute(route, { brandId }))
  }

  const whatsNewClick = () => {
    window.Beamer?.init()
    window.Beamer?.show()
  }

  const renderMenuButtons = () => {
    const permittedProducts = products.filter(product => permissions[product.key])
    const menuButtons = permittedProducts.map((product) => {
      const isActive = activeProduct === product.key && (!menuDropdownOpened || (menuDropdownOpened && productOpened?.key === product.key))
      return (
        <MenuButton
          key={product.key}
          label={product.label}
          tooltipText={!isExpanded && product.label}
          isExpanded={isExpanded}
          setIsExpanded={setIsExpanded}
          isActive={isActive}
          onProductClick={onProductClick}
          product={product.key}
          subMenu={product.children}
          dropdownOpened={menuDropdownOpened && productOpened?.key === product.key}
          setMenuDropdownOpened={setMenuDropdownOpened}
          setProductOpened={setProductOpened}
          productInfo={product}
      >
          {isExpanded
            ? <Flex align='center' justify='space-between' styles={{ width: '100%' }}>
              <Flex gap='8px' align='center'>
                <Icon name={product.icon} size={20} color={isActive ? 'primary.dark' : 'primary.light1'} />
                <Text size='14' color={isActive ? 'primary.dark' : 'primary.light1'} styles={{ fontWeight: 400 }}>
                  {product.label}
                </Text>
              </Flex>
              <Icon name='arrowright' size={20} color={isActive ? 'primary.dark' : 'primary.light1'} />
            </Flex>
            : <Icon name={product.icon} size={20} color={isActive ? 'primary.dark' : 'primary.light1'} />
        }
        </MenuButton>
      )
    }
    )

    return menuButtons
  }

  return (
    <>
      <ContainerWrapper column justify='space-between' isExpanded={isExpanded} ref={ref}>
        <Flex column align='flex-start'>
          <Logo
            src={isExpanded ? PATHS.LOGO_SVG : PATHS.LOGO_SHORT_SVG}
            onClick={() => {
              logoClick()
              setIsExpanded(false)
              setMenuDropdownOpened(false)
            }}
            isExpanded={isExpanded}
            data-cy='Navbar-Logo'
          />
          <MenuButtons>
            <MenuButton
              key='expandCollapse'
              product='expandCollapseNavBarBtn'
              label={t('commons.button.collapseMenu')}
              tooltipText={!isExpanded && t('commons.button.expandMenu')}
              onProductClick={() => { setIsExpanded(!isExpanded); setMenuDropdownOpened(false) }}
              isExpanded={isExpanded}
            >
              {isExpanded
                ? <Flex gap='8px' align='center' styles={{ width: '100%' }}>
                  <Icon name='arrowleft' size={20} color='primary.light1' />
                  <Text size='14' styles={{ fontWeight: 400 }}>
                    {t('commons.button.collapseMenu')}
                  </Text>
                </Flex>
                : <Icon name='arrowright' size={20} color='primary.light1' />
              }
            </MenuButton>
            {renderMenuButtons()}
          </MenuButtons>
        </Flex>
        <Flex column align='flex-start' gap='8px'>
          {brandId && (
            <Flex column align='flex-start' data-cy='Navbar-AvatarsContainer'>
              {showGetBeamer &&
              <>
                <MenuButton
                  key='new'
                  label={t('commons.topNavigation.whatsNew.tabName')}
                  tooltipText={!isExpanded && t('commons.topNavigation.whatsNew.tabName')}
                  isExpanded={isExpanded}
                  onProductClick={whatsNewClick}
                >
                  {isExpanded
                    ? <Flex gap='8px' align='center' styles={{ width: '100%' }}>
                      <Icon name='new' size={20} color='primary.light1' />
                      <Text size='14' styles={{ fontWeight: 400 }}>
                        {t('commons.topNavigation.whatsNew.tabName')}
                      </Text>
                    </Flex>
                    : <Icon name='new' size={20} color='primary.light1' />
                  }
                </MenuButton>
                <Spacing size='8' />
                </>
              }
              <MenuButton
                key='settings'
                label={t('commons.topNavigation.settings.tabName')}
                tooltipText={!isExpanded && t('commons.topNavigation.settings.tabName')}
                isExpanded={isExpanded}
                isActive={pathname && pathname.includes('settings')}
                onProductClick={() => {
                  setIsExpanded(false)
                  setMenuDropdownOpened(false)
                  goTo('basicInfo')
                }}
              >
                {isExpanded
                  ? <Flex gap='8px' align='center' styles={{ width: '100%' }}>
                    <Icon name='settings' size={20} color={pathname && pathname.includes('settings') ? 'primary.dark' : 'primary.light1'} />
                    <Text size='14' color={pathname && pathname.includes('settings') ? 'primary.dark' : 'primary.light1'} styles={{ fontWeight: 400 }}>
                      {t('commons.topNavigation.settings.tabName')}
                    </Text>
                  </Flex>
                  : <Icon name='settings' size={20} color={pathname && pathname.includes('settings') ? 'primary.dark' : 'primary.light1'} />
                }
              </MenuButton>
              <DottedDivider isExpanded={isExpanded} />
              <Account
                isTrial={isTrial}
                isExpanded={isExpanded}
                brandImg={brandImg}
                brandName={brandName}
                openUpgrade={handleUpgrade}
                brands={brands}
                showPricesAndPlans={showPricesAndPlans}
                showGetBeamer={showGetBeamer}
                contactFirstName={contactFirstName}
                contactImg={contactImg}
                setAvatarOpen={setAvatarOpen}
                setMenuDropdownOpened={setMenuDropdownOpened}
              />
            </Flex>
          )}
          {isPublic && (
            <LinkAsButton
              href={getStartedLink}
              target='_blank'
              color='secondary'
              variant='flat'
              styles='justify-content:flex-end;'
            >
              {t('commons.button.tryItNow')}
            </LinkAsButton>
          )}
        </Flex>
      </ContainerWrapper>
      <Dropdown
        isOpen={isAvatarOpen}
        setOpen={setAvatarOpen}
        align='left'
        styles={dropdownStyles}
        width='auto'
        isExpanded={isExpanded}
      >
        <AvatarContactHeader fullName={fullName} email={contactEmail} contactImg={contactImg} brandName={brandName} />
        <AvatarContactAccounts brandName={brandName} brands={brands} onClose={() => setAvatarOpen(false)} showGetBeamer={showGetBeamer} />
        <AvatarContactItem styles={topPositionStyles} isInPage={isAccountDefaultPage} handleClick={() => goTo('accountDefault')}>
          <Text ellipsis color='primary.light1' size='14'>
            {t('commons.topNavigation.user.dropdown.listItem.accountAndSettings.link')}
          </Text>
        </AvatarContactItem>
        <AvatarContactItem isInPage={isProductsAndBillingPage} styles={!showPricesAndPlans ? bottomPositionStyles : undefined} handleClick={() => goTo('productsAndBilling')}>
          <Text ellipsis color='primary.light1' size='14'>
            {t('commons.topNavigation.user.dropdown.listItem.servicesAndBillings.link')}
          </Text>
        </AvatarContactItem>
        {showPricesAndPlans &&
          <AvatarContactItem styles={bottomPositionStyles} isInPage={isPricesAndPlansPage} handleClick={() => goTo('pricesAndPlans')}>
            <Text ellipsis color='primary.light1' size='14'>
              {t('commons.topNavigation.user.dropdown.listItem.pricingAndFeatures.link')}
            </Text>
          </AvatarContactItem>
        }
        <AvatarContactHelpCenter helpCenterUrl={helpCenterUrlByLang} handleClick={() => setAvatarOpen(false)} />
        <AvatarContactSignOut handleClick={() => setAvatarOpen(false)} />
      </Dropdown>
      <MenuDropdown
        label={productOpened?.label}
        isMenuExpanded={isExpanded}
        isOpen={menuDropdownOpened}
        setOpen={setMenuDropdownOpened}
        subMenu={productOpened?.children}
        onProductClick={(productKey) => {
          onProductClick(productKey)
          setIsExpanded(false)
          setMenuDropdownOpened(false)
        }}
        />
    </>
  )
}

NavbarContent.defaultProps = {
  permissions: {},
  products: []
}

export { NavbarContent }
